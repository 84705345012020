:root {
  /* Variables */
  --brand-green: #53C9B4;
  --brand-orange: #C9653E;
  --font-main: #3E3E3E;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: europa, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 50px;
  
  color: #3E3E3E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
    scroll-behavior: smooth;
}

.clearfix {
    clear: both;
}

.hidden {
    display: none;
}

.section-title {
    text-align: center;
    font-size: inherit;
}

a:link {
    color: inherit;
}
a:visited {
    color: inherit;
}
/* LANDING STYLES */
.landing-container {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.landing-content {
    margin-top: 10%;
    text-align: center;
    line-height: 20px;
    left:0; 
    right:0;
    top:0; 
    bottom:0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    /* overflow: auto; */
}
.fix-bottom {
    bottom: 0;
    left: 0;
    right: 0;
}
#logo {
    width: 250px;
    margin-bottom: 60px;
}

#scroller {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 5%;
}
#arrow {
    padding-bottom: 3px;
    /* position: absolute;
    bottom: 0;
    padding-bottom: 25px; */
}

/* FONT STYLES */
.europa {
    font-family: europa, sans-serif;
    font-weight: 300;
    font-style: normal;
}
.heavy-font {
    font-family: europa, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.green-font {
    color: var(--brand-green);
}
.orange-font {
    color: var(--brand-orange);
}
.small-font {
    font-size: 20px;
}
.choice-container {
    height: 100vh;
}

.option-container {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
}
.option {
    width: 50%;
    /* height: 100%; */
    /* vertical-align: middle; */
    text-align: center;
    padding-top: 20%;
}

#projects {
    -webkit-transition: ease 0.5s;
    transition: ease 0.5s;
}
#projects:hover {
    -webkit-transition: ease 0.5s;
    transition: ease 0.5s;
    background-color: var(--brand-green);
}

#about {
    -webkit-transition: ease 0.5s;
    transition: ease 0.5s;
}
#about:hover {
    -webkit-transition: ease 0.5s;
    transition: ease 0.5s;
    background-color: var(--brand-orange);
}

#choice h2 {
    padding-top: 50px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}
#choice h3 {
    font-size: 30px;
    display: block;
    margin: 0;
    padding-top: 20px;
    /* position: relative;
    top: -40%;
    opacity: 0; */
}
/* h3:hover {
    transition: ease-out 0.5s;
    top: 0%;
    opacity: 1;
} */


@media only screen and (max-width: 500px) {
    .choice-container {
        position: relative;
    }
    
    .option-container {
        height: 80%;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    
    .option {
        width: 100%;
        padding-top: 0;
    }

    *:hover {
        background-color: inherit;
    }

    #choice h2 {
        font-size: 40px;
        padding-top: 0;
    }
}


form {
    
}
form.label {
    font-size: 20px;
}
.admin-wrapper h1 {
    font-size: 48pt;
}

p {
    font-size: 24pt;
}

.admin-wrapper {
    margin: 50px;
}
.project-container {
	width: 20%;
	margin: 3%;
	padding: 25px;
}

.project-desc {
	overflow: scroll;
	height: 300px;
}

.project-container h2 {
	font-size: 0.6em;
}

.project-container p {
	font-size: 0.5em;
	margin-top: 0;
}

.project-container hr {
	border: 2px solid;
	border-radius: 2px;
}

@media only screen and (max-width: 700px) {
	.project-container {
		width: 100%;
	}
	.project-header {
		width: 100%;
	}
}
.project-header {
    display: inline-grid;
    grid-template-columns: auto 25%;
}
.vert-align {
    display: -webkit-flex;
    display: flex;
}
.plusIcon {
    margin: auto;
}

#seperator {
    display: none;
}
.list-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}

@media only screen and (max-width: 700px) {
    .list-container-mobile {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
.projects h1 {
    font-size: 50px;
    text-align: center;
}
.about {
    display: grid;
    grid-template-areas: 'sidebar content content';
    grid-auto-columns: 25%;
}

.about-sidebar {
    grid-area: sidebar;
    height: 100%;
    width: 25%;
    text-align: center;
    position: fixed;
}
.about-sidebar img {
    width: 175px;
    border-radius: 50%;
    margin-top: 150px;
}
.about-sidebar h1 {
    font-size: 40px;
    margin: 0;
}
.about-sidebar h2 {
    font-size: 25px;
    margin: 0;
    font-weight: 200;
}
.about-socials {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 25%;
    margin: auto;
}
.about-sidebar .about-socials .icon {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 150px;
}

.about-content {
    grid-area: content;
    margin-left: 150px;
    padding-bottom: 150px;
}
.about-content h1 {
    font-size: 50px;
    padding-top: 150px;
}
.about-content h2 {
    font-size: 30px;
    margin-bottom: 0;
}
.about-content p {
    font-size: 25px;
    margin-top: 0;
}

.about #scroller {
    display: none;
}

@media only screen and (max-width: 700px) {
    .about #scroller {
        display: inline;
        display: initial;
    }
    .about {
        display: grid;
        grid-template-areas: "sidebar"
                             "content";
        grid-auto-columns: 100%;
    }
    .about-sidebar {
        grid-area: sidebar;
        height: 100vh;
        width: auto;
        margin-top: 1em;
        margin-bottom: 1em;
        text-align: center;
        position: static;
        position: initial;
    }
    .about-sidebar img {
        margin-top: 1rem;
    }
    .about-socials {
        position: static;
        bottom: auto;
        left: auto;
        width: 100%;
        height: auto;
        margin: auto;
    }
    .about-sidebar .about-socials .icon {
        margin-bottom: 0px;
    }
    .about-content {
        grid-area: content;
        margin-left: 0px;
        padding-bottom: 0px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .about-content h1 {
        /* text-align: center; */
        padding-top: 0px;
    }
    .about-content p {
        font-size: 1.25rem;
    }
}

