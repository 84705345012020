.project-container {
	width: 20%;
	margin: 3%;
	padding: 25px;
}

.project-desc {
	overflow: scroll;
	height: 300px;
}

.project-container h2 {
	font-size: 0.6em;
}

.project-container p {
	font-size: 0.5em;
	margin-top: 0;
}

.project-container hr {
	border: 2px solid;
	border-radius: 2px;
}

@media only screen and (max-width: 700px) {
	.project-container {
		width: 100%;
	}
	.project-header {
		width: 100%;
	}
}