html {
    scroll-behavior: smooth;
}

.clearfix {
    clear: both;
}

.hidden {
    display: none;
}

.section-title {
    text-align: center;
    font-size: inherit;
}

a:link {
    color: inherit;
}
a:visited {
    color: inherit;
}