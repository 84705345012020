.project-header {
    display: inline-grid;
    grid-template-columns: auto 25%;
}
.vert-align {
    display: flex;
}
.plusIcon {
    margin: auto;
}

#seperator {
    display: none;
}