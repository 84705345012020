.list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 700px) {
    .list-container-mobile {
        flex-direction: column;
    }
}