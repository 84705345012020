.choice-container {
    height: 100vh;
}

.option-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
}
.option {
    width: 50%;
    /* height: 100%; */
    /* vertical-align: middle; */
    text-align: center;
    padding-top: 20%;
}

#projects {
    transition: ease 0.5s;
}
#projects:hover {
    transition: ease 0.5s;
    background-color: var(--brand-green);
}

#about {
    transition: ease 0.5s;
}
#about:hover {
    transition: ease 0.5s;
    background-color: var(--brand-orange);
}

#choice h2 {
    padding-top: 50px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}
#choice h3 {
    font-size: 30px;
    display: block;
    margin: 0;
    padding-top: 20px;
    /* position: relative;
    top: -40%;
    opacity: 0; */
}
/* h3:hover {
    transition: ease-out 0.5s;
    top: 0%;
    opacity: 1;
} */


@media only screen and (max-width: 500px) {
    .choice-container {
        position: relative;
    }
    
    .option-container {
        height: 80%;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
    
    .option {
        width: 100%;
        padding-top: 0;
    }

    *:hover {
        background-color: inherit;
    }

    #choice h2 {
        font-size: 40px;
        padding-top: 0;
    }
}
