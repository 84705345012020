.admin-wrapper h1 {
    font-size: 48pt;
}

p {
    font-size: 24pt;
}

.admin-wrapper {
    margin: 50px;
}