.about {
    display: grid;
    grid-template-areas: 'sidebar content content';
    grid-auto-columns: 25%;
}

.about-sidebar {
    grid-area: sidebar;
    height: 100%;
    width: 25%;
    text-align: center;
    position: fixed;
}
.about-sidebar img {
    width: 175px;
    border-radius: 50%;
    margin-top: 150px;
}
.about-sidebar h1 {
    font-size: 40px;
    margin: 0;
}
.about-sidebar h2 {
    font-size: 25px;
    margin: 0;
    font-weight: 200;
}
.about-socials {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 25%;
    margin: auto;
}
.about-sidebar .about-socials .icon {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 150px;
}

.about-content {
    grid-area: content;
    margin-left: 150px;
    padding-bottom: 150px;
}
.about-content h1 {
    font-size: 50px;
    padding-top: 150px;
}
.about-content h2 {
    font-size: 30px;
    margin-bottom: 0;
}
.about-content p {
    font-size: 25px;
    margin-top: 0;
}

.about #scroller {
    display: none;
}

@media only screen and (max-width: 700px) {
    .about #scroller {
        display: initial;
    }
    .about {
        display: grid;
        grid-template-areas: "sidebar"
                             "content";
        grid-auto-columns: 100%;
    }
    .about-sidebar {
        grid-area: sidebar;
        height: 100vh;
        width: auto;
        margin-top: 1em;
        margin-bottom: 1em;
        text-align: center;
        position: initial;
    }
    .about-sidebar img {
        margin-top: 1rem;
    }
    .about-socials {
        position: static;
        bottom: auto;
        left: auto;
        width: 100%;
        height: auto;
        margin: auto;
    }
    .about-sidebar .about-socials .icon {
        margin-bottom: 0px;
    }
    .about-content {
        grid-area: content;
        margin-left: 0px;
        padding-bottom: 0px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .about-content h1 {
        /* text-align: center; */
        padding-top: 0px;
    }
    .about-content p {
        font-size: 1.25rem;
    }
}