/* LANDING STYLES */
.landing-container {
    height: 100vh;
    display: flex;
    justify-content: center;
}
.landing-content {
    margin-top: 10%;
    text-align: center;
    line-height: 20px;
    left:0; 
    right:0;
    top:0; 
    bottom:0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    /* overflow: auto; */
}
.fix-bottom {
    bottom: 0;
    left: 0;
    right: 0;
}
#logo {
    width: 250px;
    margin-bottom: 60px;
}

#scroller {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 5%;
}
#arrow {
    padding-bottom: 3px;
    /* position: absolute;
    bottom: 0;
    padding-bottom: 25px; */
}

/* FONT STYLES */
.europa {
    font-family: europa, sans-serif;
    font-weight: 300;
    font-style: normal;
}
.heavy-font {
    font-family: europa, sans-serif;
    font-weight: 400;
    font-style: normal;
}
.green-font {
    color: var(--brand-green);
}
.orange-font {
    color: var(--brand-orange);
}
.small-font {
    font-size: 20px;
}